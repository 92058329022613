import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { Link, useLocation } from 'react-router-dom'
import { Routes } from '@root-gipro/router'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	buttonGroup: {
		borderRadius: '3px',
		overflow: 'hidden',
	},
	button: {
		minWidth: '100px',
		padding: '3px 5px',
		color: 'gray !important',
		textTransform: 'unset',
		'&.Mui-selected': {
			backgroundColor: '#4fc3f7',
			color: '#ffffff',
		},
	},
	selected: {
		backgroundColor: '#1baaf0 !important',
		color: '#ffffff !important',
	},
}))

const ToggleSwitch = () => {
	const classes = useStyles()
	const path = useLocation()
	const searchUrl: string = path.search

	const [selected, setSelected] = useState('cards')
	useEffect(() => {
		if (
			path.pathname === Routes.COMMON_PROJECTS ||
			path.pathname === Routes.FAVORITES_COMMON_PROJECTS ||
			path.pathname === Routes.FAVORITES_PURCHASES ||
			path.pathname === Routes.PURCHASES
		) {
			setSelected('cards')
		}
		if (
			path.pathname === Routes.COMMON_PROJECTS_TABLE ||
			path.pathname === Routes.FAVORITES_COMMON_PROJECTS_TABLE ||
			path.pathname === Routes.FAVORITES_PURCHASES_TABLE ||
			path.pathname === Routes.PURCHASES_TABLE
		) {

			setSelected('table')
		}
		return () => {
		}
	}, [path.pathname])
	const handleButtonClick = (value: any) => {
		setSelected(value)
	}
	const linkHandler = () => {
		let currentPath = path.pathname

		if (selected === 'table') {
			// Удаляю 'table' в конце пути, если он есть
			if (currentPath.endsWith('/table')) {
				return `${currentPath.slice(0, -6)}${searchUrl ? `?${searchUrl}` : ''}` // Удаляю последние 6 символов ("/table")
			}
			return currentPath
		} else {
			// Добавляю 'table' в конце пути, если его нет
			if (!currentPath.endsWith('/table')) {
				return `${currentPath}/table${searchUrl ? `?${searchUrl}` : ''}`
			}
			return currentPath
		}
	}
	return (
		<div className={classes.root}>
			<ButtonGroup className={classes.buttonGroup}>
				<Link to={linkHandler()} onClick={(e: any) => 'cards' === selected && e.preventDefault()}>
					<Button
						className={`${classes.button} ${selected === 'cards' && classes.selected}`}
						onClick={() => handleButtonClick('cards')}
					>
						Карточки
					</Button>
				</Link>
				<Link to={linkHandler()} onClick={(e: any) => 'table' === selected && e.preventDefault()}>
					<Button
						className={`${classes.button} ${selected === 'table' && classes.selected}`}
						onClick={() => handleButtonClick('table')}
					>
						Таблица
					</Button>
				</Link>
			</ButtonGroup>
		</div>
	)
}

export default ToggleSwitch
