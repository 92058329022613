import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import DownloadIcon from '@material-ui/icons/GetApp'
import { Divider } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { downloadTableCommonProjects } from '@root-gipro/modules/commonProjects/store/actions'
import { IState } from '@root-gipro/store/interfaces'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	button: {
		textTransform: 'none',
		border: '1px solid #adacac',
		borderRadius: '8px',
		padding: '3px 8px !important',
		color: 'gray',
	},
	menu: {
		marginTop: theme.spacing(1),
	},
	menuItem: {
		minWidth: '120px',
		padding: theme.spacing(1, 2),
	},
}))

const DownloadMenu = () => {
	const classes = useStyles()
	const [anchorEl, setAnchorEl] = useState(null)
	const [downloadType, setDownloadType] = useState<string>('projects')
	const dispatch = useDispatch()
	const path = useLocation()
	const { sortName, name, order } = useSelector((state: IState) => state.userProjects.sort)
	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget)
	}
	useEffect(() => {
		if (path.pathname.includes('purchases')) {
			setDownloadType('purchases')
		} else {
			setDownloadType('projects')
		}
	}, [])
	const handleClose = () => {
		setAnchorEl(null)
	}
  const handleDowload = () => {
		dispatch(downloadTableCommonProjects(name, sortName, downloadType, !!order))
		setAnchorEl(null)
	}

	return (
		<div className={classes.root}>
			<Button className={classes.button} startIcon={<DownloadIcon />} onClick={handleClick}>
				Скачать
			</Button>
			<Menu
				id='customized-menu'
				className={classes.menu}
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				PaperProps={{
					style: {
						border: '2px solid #d3d4d5',
						borderRadius: '4px',
						marginTop: '30px',
					},
				}}
			>
				<MenuItem className={classes.menuItem} onClick={handleDowload}>
					Таблица
				</MenuItem>
				<Divider />
			</Menu>
		</div>
	)
}

export default DownloadMenu
